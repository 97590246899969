<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            Billing Monitor
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-title>
            <v-flex xs10>
              <v-student-search-field
                @onSelectStudent="onSelectStudent"
                :company-id="$route.params.id"
              ></v-student-search-field>
            </v-flex>
            <v-flex xs2>
              <v-btn block outlined @click="fetchStudentRecord">Search</v-btn>
            </v-flex>
          </v-card-title>

          <v-card-title>
            <div class="tree_view_transaction_report">
              <div
                class="transaction_list"
                v-for="(transaction, key) in transaction_list"
                :key="key"
              >
                <v-card-title>
                  <h3>
                    Receipt No: {{ transaction.bill_no }}, &nbsp;&nbsp;&nbsp;
                    <small>({{ transaction.amount.numberFormat() }})</small>
                  </h3>
                  <v-spacer />
                  <v-btn @click="checker(transaction)">Check</v-btn>
                </v-card-title>
                <div
                  class="invoice_list"
                  v-for="(invoice, key2) in transaction.invoices"
                  :key="key2"
                >
                  <v-card-title
                    >{{ invoice.invoice_no }}&nbsp;
                    <small
                      >({{ invoice.amount.numberFormat() }}) Bill Month:
                      {{ invoice.bill_month }}
                    </small>
                  </v-card-title>
                  <div
                    @dblclick="checkInvoiceStatus(transaction, invoice)"
                    class="invoice_details"
                  >
                    <table class="table table-bordered">
                      <tr>
                        <th></th>
                        <th>Head</th>
                        <th>Amount</th>
                        <th>Paid</th>
                        <th>Due</th>
                        <th>Discount</th>
                        <th>#</th>
                      </tr>
                      <tr
                        v-for="(detail, kk) in invoice.details"
                        :key="kk"
                        :class="{ is_paid: isThisPaid(transaction, detail) }"
                      >
                        <td>
                          <v-icon v-if="detail.mark_as_paid === true"
                            >check_circle</v-icon
                          >
                        </td>
                        <td>{{ detail.fee_head }}</td>
                        <td>{{ detail.total }}</td>
                        <td>{{ detail.paid_amount }}</td>
                        <td>{{ detail.due_amount }}</td>
                        <td>{{ detail.instant_discount }}</td>
                        <td>
                          <v-btn @click="markPaid(transaction, invoice, detail)"
                            >Mark Paid
                          </v-btn>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    selectedStudent: null,
    transaction_list: [],
  }),
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    onSelectStudent(onSelectStudent) {
      this.selectedStudent = onSelectStudent;
      this.fetchStudentRecord();
    },
    isThisPaid(transaction, detail) {
      let output = false;
      transaction.invoice_transaction.map((item) => {
        if (item.invoice_detail_id === detail.id) {
          output = true;
        }
      });
      return output;
    },
    fetchStudentRecord() {
      const endpoint = "/api/report/monitoring/GPB-76064";
      this.$rest.get(endpoint).then((res) => {
        this.transaction_list = res.data;
        res.data.map((item) => {});
      });
      // }
    },
    checker(transaction) {
      transaction.invoices.map(function() {});
    },
    checkInvoiceStatus(transaction, invoice) {
      transaction.invoices.map(function(item) {
        if (item.id === invoice.id) {
        }
      });
    },
    markPaid(transaction, invoice, invoiceDetails) {
      if (
        transaction.amount > 0 &&
        transaction.amount >= invoiceDetails.paid_amount
      ) {
        transaction.amount -=
          invoiceDetails.paid_amount - invoiceDetails.instant_discount;
        // invoiceDetails.mark_as_paid = true;
        this.transaction_list.map(function(transaction) {
          transaction.invoices.map((invoice) => {
            invoice.details.map((detail) => {
              if (detail.id === invoiceDetails.id) {
                detail.mark_as_paid = true;
              }
            });
          });
        });
      }

      this.isAllPaid(invoice);
    },
    isAllPaid(invoice) {
      invoice.details.map((item) => {
        // console.log(item);
      });
    },
  },
};
</script>

<style lang="scss">
.error_light {
  background-color: rgba(225, 0, 0, 0.2);
}

tr.unprocess {
  td {
    border-bottom: 4px solid indianred !important;
  }
}

.tree_view_transaction_report {
  width: 100%;
}

.transaction_list {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  h3 {
    padding-bottom: 10px;
  }
  .invoice_list {
    width: 100%;
    padding: 20px;
    background-color: #ccc;
    .invoice_details {
      padding: 20px;
      background-color: #999;
    }
  }
}
tr.is_paid {
  background-color: green;
  color: white;
}
</style>
